import React from "react"
import placeholder from "../../images/me.png"
import styled from "@emotion/styled"

const Card = styled.div`
  height: 290px;
  width: 100%;
  background-color: #e8ebed;
  font-family: roboto;
  box-shadow: 1px 3px 15px black;
  background-position: center top;
  position: relative;
  overflow: hidden;
  background-image: url(${props => props.background});
  background-size: cover;
`

const Rank = styled.div`
  border: 25px solid darkorange;
  border-bottom: 30px solid transparent;
  position: absolute;
  right: 20px;
  height: 80px;
  span {
    text-align: center;
    position: absolute;
    top: -22px;
    left: ${props => (props.higherThanTen ? "-13px" : "-7px")};
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 20px;
  }
`
const Content = styled.div`
  display: block;
  color: white;
  z-index: 100;
  background-color: rgba(0, 51, 87, 0.8);
  position: absolute;
  bottom: 0;
  text-align: center;
  font-family: roboto;
  width: 100%;
  padding: 0.5rem;
  h2 {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 700;
  }
  p {
    margin-bottom: 0;
    font-size: 0.7rem;
    font-weight: 300;
  }
`
const EaterCard = ({ nameF, nameL, rank, hometown, image }) => {
  return (
    <Card background={(image && image.publicURL) || placeholder}>
      <Rank higherThanTen={rank >= 10}>
        <span>
          <small>#</small> {rank}
        </span>
      </Rank>
      <Content>
        <h2>
          {nameF} {nameL}
        </h2>
        <p>{hometown}</p>
      </Content>
    </Card>
  )
}

export default EaterCard
