import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import EaterCard from "../components/Cards/eater"
import styled from "@emotion/styled"
import Banner from "../components/banner"
import { Paragraph, Header1 } from "../components/headings"
import ContentContainer from "../components/contentContainer"
import SEO from "../components/seo"

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  li {
    margin: 1rem;
    flex: 1 0 21%;
    list-style: none;
    @media (max-width: 768px) {
      flex: 1 0 40%;
    }
    @media (max-width: 576px) {
      flex: 1 0 50%;
    }
  }
`

const ProfilesPage = ({ data }) => (
  <Layout>
    <SEO title="Rankings" keywords={[`eating`, `contests`, `official`]} />
    <Banner img={data.placeholderImage.childImageSharp.fluid}>
      <Header1>Eating Rankings</Header1>
      <Paragraph>
        Perched atop the food chain is the apex predator himself, Joey Chestnut.
        But never count out #50 because today’s table-ender is tomorrow’s
        superstar. Major League Eating ranks 50 league members on a regular
        basis. Meet the men and women who are the true ambassadors of
        competitive eating.
      </Paragraph>
    </Banner>
    <ContentContainer>
      {" "}
      <List>
        {data.allStrapiProfiles.nodes.map(document => (
          <li key={document.id}>
            <Link to={`/eaters/${document.sn}`}>
              <EaterCard {...document} />
            </Link>
          </li>
        ))}
        <li>&nbsp;</li>
        <li>&nbsp;</li>
      </List>
    </ContentContainer>
  </Layout>
)

export default ProfilesPage

export const pageQuery = graphql`
  query ProfilesQuery {
    allStrapiProfiles(filter: { rank: { lte: 50 } }, sort: { fields: [rank] }) {
      nodes {
        nameF
        nameL
        rank
        hometown
        sn
        image {
          publicURL
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "eater-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
